import { Controller } from "stimulus";
import DataUtils from "../util/data_utils";
import Spinner from "../util/spinner";
import Tracker from "../util/tracker";

export default class extends Controller {
    static targets = [
        "wrapperContainer",
        "dropdownElement",
        "loadingContainer",
        "seen",
        "badge",
    ];

    private wrapperContainerTarget: HTMLElement;
    private dropdownElementTarget: HTMLElement;
    private loadingContainerTarget: HTMLElement;
    private seenTargets: HTMLElement[];
    private badgeTarget: HTMLElement;


    private spinner: Spinner;
    private bottomScrollDetectOffset = 50;
    private isLoading = false;
    private morePagesAvailable = true;
    private total = 0;
    private nextDate: string;

    connect() {
        this.nextDate = (this.element as HTMLElement).dataset.streamNextdate;
    }

    disconnect() {
        document?.removeEventListener('click', this.viewNotificationClickListener);
    }

    async openNotification() {
        if (!this.data.get('notification-stream-initialized')) {
            this.data.set('notification-stream-initialized', 'true');

            this.trackClickedEvents();
            await this.loadNextPage();
            this.observeScrolling();
        }
    }

    disableBodyScroll() {
        document.body.style.overflowY = 'scroll';
        document.body.style.position = 'fixed';
    }

    enableBodyScroll() {
        document.body.style.overflowY = 'auto';
        document.body.style.position = 'static';
    }

    markRead() {
        this.badgeTarget.classList.remove("unread-indicator");
        this.seenTargets.forEach(seen => {
            seen.dataset.notificationStreamSeen = "true";
            seen.classList.remove("notifications-list__item--unread");
            seen.classList.add("notifications-list__item--read");
        });
    }

    private observeScrolling() {
        this.dropdownElementTarget.addEventListener('scroll', async () => {
            if (this.reachedNearBottomOfPage()) {
                if (!(this.isCurrentlyLoading() || this.noMorePagesAreAvailable())) {
                    await this.loadNextPage();
                }
            }
        });
    }

    private reachedNearBottomOfPage(): boolean {
        return this.dropdownElementTarget.scrollTop + this.dropdownElementTarget.offsetHeight > this.wrapperContainerTarget.offsetHeight - this.bottomScrollDetectOffset;
    }

    private markAsIsLoading() {
        this.isLoading = true;
        this.displayLoadingAnimation();
    }

    private markAsDoneLoading() {
        this.isLoading = false;
        this.removeLoadingAnimation();
    }

    private displayLoadingAnimation() {
        this.loadingContainerTarget.classList.remove("hidden");
        this.spinner = new Spinner(this.loadingContainerTarget);
        this.spinner.start();
    }

    private removeLoadingAnimation() {
        this.spinner.stop();
        this.loadingContainerTarget.classList.add("hidden");
    }

    private markAsNoMorePagesAvailable() {
        this.morePagesAvailable = false;
    }

    private noMorePagesAreAvailable() {
        return this.morePagesAvailable === false;
    }

    private isCurrentlyLoading() {
        return this.isLoading === true;
    }

    private async loadNextPage() {
        this.markAsIsLoading();
        const nextDate = this.nextDate && { nextdate: this.nextDate };

        let data = await DataUtils.request((this.element as HTMLElement).dataset.streamUrl,
            { ...nextDate },
            { parse: false }
        ).catch(() => {
            console.error('Unexpected error with downloading notificiation list');
        });
        if (data) {
            data = await data.text();

            const tmpElement = document.createElement('div');
            tmpElement.insertAdjacentHTML('beforeend', data);

            const notificationsElements = tmpElement.querySelector('.notifications-list') as HTMLElement;
            this.nextDate = notificationsElements.dataset.streamNextdate;
            const perPage = notificationsElements.dataset.streamPerpage;

            const newListItems = tmpElement.querySelectorAll("[data-role='list-item']") as NodeListOf<HTMLElement>;
            this.markAsDoneLoading();

            if (this.total > 0 && (tmpElement.querySelector("[data-role='list-item']") as HTMLElement).dataset.noNotifications) {
                this.markAsNoMorePagesAvailable();
            }

            this.total = this.total + newListItems.length;

            if (newListItems.length > 0) {
                this.wrapperContainerTarget.append(tmpElement.firstChild);
            }

            if (newListItems.length === parseInt(perPage)) {
                if ((this.dropdownElementTarget.offsetHeight - this.wrapperContainerTarget.offsetHeight) >= 0) {
                    await this.loadNextPage();
                }
            } else {
                this.markAsNoMorePagesAvailable();
            }
        }
    }

    private trackClickedEvents() {
        document.addEventListener('click', this.viewNotificationClickListener);
    }

    private viewNotificationClickListener(e) {
        if (e.target) {
            const target = e.target as HTMLElement;

            if (target.classList.contains('view-notification-link')) {
                Tracker.track("Clicked Notification Action", {
                    "Source": "Translation Center",
                    "Scope": "NavigationDropdown",
                    "Event": target.dataset.eventName
                });
            }
        }
    }
}
