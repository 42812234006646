import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class KeyFilterTurboController extends Controller {
    private url: URL;

    connect() {
        this.url = new URL(window.location.href);
        this.element.addEventListener("syntax-list-multiselect:update-value", this.filter.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("syntax-list-multiselect:update-value", this.filter.bind(this));
    }

    filter(event: CustomEvent) {
        const MAX_AMOUNT_PARAMS = 150;
        const usedFilterKeys = [];

        event.detail.items.forEach((object) => {
            const itemWasAdded = object.action === "added";
            const input = object.element.querySelector("input") as HTMLInputElement;
            const filterKey = input.dataset.filterKey;
            usedFilterKeys.push(filterKey);

            if (itemWasAdded) {
                this.url.searchParams.append(filterKey, input.value);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.url.searchParams.delete(filterKey, input.value);
            }
        });

        usedFilterKeys.forEach((filterKey) => {
            // guard against too many filter parameters in GET query
            if(this.url.searchParams.getAll(filterKey).length > MAX_AMOUNT_PARAMS) {
                const limitedParams = this.url.searchParams.getAll(filterKey).slice(0, MAX_AMOUNT_PARAMS);
                this.url.searchParams.delete(filterKey);

                for (const value of limitedParams) {
                    this.url.searchParams.append(filterKey, value);
                }
            }
        });

        Turbo.visit(this.url.href, { action: "replace", frame: this.data.get("frame-id") });
    }
}
