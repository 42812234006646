import { Controller } from "stimulus";
import Spinner from "../util/spinner";
import Sentry from "@utils/sentry";
import { update } from "../util/upload_status";
import * as Navigation from "../util/navigation";
import Tracker from "@utils/tracker";

const INITIAL_POLL_FREQUENCY = 300;
const POLL_FREQUENCY = 5000;

export default class extends Controller {
    static targets = [
        "statusTitle",
        "statusDescription",
        "errorMessage",
        "statusMessage",
        "spinnerContainer",
    ];

    element: HTMLElement;
    statusTitleTarget: HTMLElement;
    statusDescriptionTarget: HTMLElement;
    statusMessageTarget: HTMLElement;
    errorMessageTarget: HTMLElement;
    spinnerContainerTarget: HTMLDivElement;
    hasSpinnerContainerTarget: boolean;

    private spinner: Spinner;
    private pollingCount: number;

    connect() {
        this.pollingCount = 0;

        const state = this.initialState();

        if (this.hasSpinnerContainerTarget) {
            this.spinner = new Spinner(this.spinnerContainerTarget);
        }

        if (state === "processing") {
            this.startSpinner();
            this.updateStatus();
        } else if (state === "cloning") {
            this.startSpinner();
            setTimeout(() => Navigation.load(this.redirectUrl()), 2000);
        }
    }

    private updateStatus() {
        this.pollingCount = this.pollingCount + 1;

        fetch(this.data.get("updateUrl"))
            .then(response => response.json())
            .then(this.handleUpdateStatusResponse.bind(this))
            .catch(err => {
                this.displayErrorMessage();
                this.trackEvent("Error Occured While Fetching File Upload Status");
                Sentry.notify(err);
            });
    }

    private handleUpdateStatusResponse(data: { state: string }) {
        const action = update(this.redirectUrl(), this.pollingCount, data.state);

        switch (action.kind) {
            case "redirectTo":
                Navigation.load(action.url);
                break;

            case "reload":
                Navigation.reload();
                break;

            case "phaseOnePolling":
                this.statusTitleTarget.innerText = this.data.get("phase-one-title");
                this.statusDescriptionTarget.innerText = this.data.get("phase-one-description");
                this.startTimer(INITIAL_POLL_FREQUENCY);
                break;

            case "phaseTwoPolling":
                this.statusTitleTarget.innerText = this.data.get("phase-two-title");
                this.statusDescriptionTarget.innerText = this.data.get("phase-two-description");
                this.startTimer(POLL_FREQUENCY);
                break;

            case "phaseThreePolling":
                this.statusTitleTarget.innerText = this.data.get("phase-three-title");
                this.statusDescriptionTarget.innerText = this.data.get("phase-three-description");
                this.startTimer(POLL_FREQUENCY);
                break;

            case "stopPolling":
                this.displayErrorMessage();
                this.trackEvent("File Upload Timed Out");
                break;
        }
    }

    private startSpinner() {
        if (this.spinner) {
            this.spinner.start();
        }
    }

    private stopSpinner() {
        if (this.spinner) {
            this.spinner.stop();
        }
    }

    private initialState(): string | null {
        return this.data.get("state");
    }

    private redirectUrl(): string {
        return this.data.get("redirect-url");
    }

    private trackEvent(eventName: string) {
        Tracker.track(
            eventName,
            {
                "Source": "Translation Center",
                "Scope": "Project Setup",
            }
        );
    }

    private startTimer(pollFrequency: number) {
        window.setTimeout(this.updateStatus.bind(this), pollFrequency);
    }

    private displayErrorMessage() {
        this.stopSpinner();
        this.statusMessageTarget.classList.add("hidden");
        this.errorMessageTarget.classList.remove("hidden");
    }
}
