import { Controller } from "stimulus";
import { i18n } from "../i18n/config";
import Sentry from "@utils/sentry";
import { selectizeOf } from "@utils/selectize";

export default class UploadFormController extends Controller {

    static targets = [
        "fileInput",
        "formatSelection",
        "submitButton",
        "submitToPreviewButton",
        "localeSelection",
        "hintForNoneLocales",
        "hintForPreview",
        "formatOptions",
        "createNewLocale",
        "useExistingLocale",
        "detectLocale",
    ];

    fileInputTarget: HTMLInputElement;
    formatSelectionTarget: HTMLInputElement;
    submitButtonTarget: HTMLButtonElement;
    submitToPreviewButtonTarget: HTMLButtonElement;
    localeSelectionTarget: HTMLDivElement;
    hintForNoneLocalesTarget: HTMLDivElement;
    hintForPreviewTarget: HTMLDivElement;
    formatOptionsTargets: [HTMLDivElement];
    createNewLocaleTarget: HTMLDivElement;
    useExistingLocaleTarget: HTMLDivElement;
    detectLocaleTarget: HTMLDivElement;

    private projectMainFormat: string;
    private formats: any;

    connect() {
        this.formats = JSON.parse(this.data.get("formats"));
        window.requestAnimationFrame(() => {
            const uploadNewLocale = document.querySelector(".upload_form_new_locale_name .selectize-control");
            if (uploadNewLocale) {
                uploadNewLocale
                    .addEventListener("click", () => {
                        const createNewLocaleRadioButton =
                            <HTMLInputElement>document.getElementById("upload_form_locale_mode_new");
                        if (createNewLocaleRadioButton) {
                            createNewLocaleRadioButton.checked = true;
                        }
                    });
            }

            document.querySelector(".upload_form_locale_id .selectize-control")
                .addEventListener("click", () => {
                    const chooseExistingLocaleRadioButton =
                        <HTMLInputElement>document.getElementById("upload_form_locale_mode_existing");
                    if (chooseExistingLocaleRadioButton) {
                        chooseExistingLocaleRadioButton.checked = true;
                    }
                });

            const newSourceLocaleName = document.querySelector(".upload_form_new_source_locale_name .selectize-control");
            if(newSourceLocaleName){
                newSourceLocaleName
                    .addEventListener("click", () => {
                        const chooseNewSourceLocaleRadioButton =
                            <HTMLInputElement>document.querySelector("#upload_form_source_locale_mode_new");
                        if (chooseNewSourceLocaleRadioButton) {
                            chooseNewSourceLocaleRadioButton.checked = true;
                        }
                    });
            }

            const existingSourceLocaleId = document.querySelector(".upload_form_source_locale_id .selectize-control");
            if(existingSourceLocaleId){
                existingSourceLocaleId
                    .addEventListener("click", () => {
                        const chooseExistingSourceLocaleRadioButton =
                            <HTMLInputElement>document.querySelector("#upload_form_source_locale_mode_existing");
                        if (chooseExistingSourceLocaleRadioButton) {
                            chooseExistingSourceLocaleRadioButton.checked = true;
                        }
                    });
            }

            (<any>window)
                .$(document.querySelector("[data-role='format-selection']"))
                .on("change", () => {
                    this.refreshForm();

                    const bilingualMode = <HTMLInputElement>document.querySelector("#upload_form_format_options_is_bilingual_file");
                    if (bilingualMode) {
                        bilingualMode.checked = false;
                        this.bilingualMode();
                    }
                });

            this.projectMainFormat = this.formatSelectionTarget.value;
        });

        this.refreshForm();
        this.refreshFormatDropdown();
    }

    bilingualMode() {
        const bilingualMode = <HTMLInputElement>document.querySelector("#upload_form_format_options_is_bilingual_file");
        const bilingualModeForm  = document.querySelector("#bilingual-mode-form");

        const msgidByDefault = <HTMLInputElement>document.querySelector("#upload_form_format_options_msgid_as_default");

        if(!bilingualMode || !bilingualModeForm)
            return null;

        if (bilingualMode.checked) {
            bilingualModeForm.classList.remove("hidden");
            if(msgidByDefault){
                msgidByDefault.disabled = true;
                msgidByDefault.checked = false;
            }
        } else {
            bilingualModeForm.classList.add("hidden");
            if(msgidByDefault){
                msgidByDefault.disabled = false;
            }
        }

    }

    fileInputChanged() {
        this.refreshFormatDropdown();
    }

    private refreshFormatDropdown() {
        const nameParts = this.fileInputTarget.value.split(".");
        let fileExtension = nameParts.pop();
        if (fileExtension === "gz") {
            fileExtension = nameParts.pop();
        }
        if (!fileExtension) {
            return;
        }

        fileExtension = fileExtension.toLowerCase();
        if (this.keepSelectedFormat(fileExtension)) {
            return;
        }

        const suggestedFormats = [];
        const concealedFormats = [];
        Object.keys(this.formats).forEach(key => {
            const value = this.formats[key];
            const format = { value: key, text: value["display_name"] };
            if (value["extension"] === fileExtension) {
                suggestedFormats.push(format);
            } else {
                concealedFormats.push(format);
            }
        });

        selectizeOf(this.formatSelectionTarget)
            .then((selectize) => {
                if (suggestedFormats.length === 1) {
                    selectize.setValue(suggestedFormats[0].value);
                } else if (suggestedFormats.length > 1) {
                    selectize.clearOptions();
                    suggestedFormats
                        .concat(concealedFormats)
                        .forEach(format => selectize.addOption(format));
                }
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private keepSelectedFormat(fileExtension: string): boolean {
        if (!this.formatSelectionTarget || !this.projectMainFormat || this.formatSelectionTarget.value === "") {
            return false;
        }

        const selectedFormatExtension = this.formats[this.formatSelectionTarget.value]['extension'];
        const projectFormatExtension = this.formats[this.projectMainFormat]['extension'];

        return selectedFormatExtension === projectFormatExtension
            && projectFormatExtension === fileExtension;
    }

    private refreshForm() {
        const allFormatOptions = this.formatOptionsTargets;
        for (let i = 0; i < allFormatOptions.length; i++) {
            allFormatOptions[i].classList.add("hidden");
        }
        const allFormatOptionInputs: NodeListOf<HTMLInputElement> =
      document.querySelectorAll(".format-options input");
        for (let j = 0; j < allFormatOptionInputs.length; j++) {
            allFormatOptionInputs[j].disabled = true;
        }

        const format = this.formatSelectionTarget.value;

        let formatOptions = null;
        if (format) {
            formatOptions = document.querySelector(`.format-options.${format}`);

            const allFormatOptionInputsForFormat: NodeListOf<HTMLInputElement> =
        document.querySelectorAll(`.format-options.${format} input`);
            for (let k = 0; k < allFormatOptionInputsForFormat.length; k++) {
                allFormatOptionInputsForFormat[k].disabled = false;
            }
        }

        if (formatOptions) {
            formatOptions.classList.remove("hidden");
            formatOptions.setAttribute("disabled", "false");
        } else {
            this.submitButtonTarget.classList.remove("hidden");
            this.submitToPreviewButtonTarget.classList.add("hidden");
            this.localeSelectionTarget.classList.remove("hidden");
            this.hintForNoneLocalesTarget.classList.add("hidden");
            this.hintForPreviewTarget.classList.add("hidden");
        }

        if (format) {
            this.refreshFormOptions(format);
            this.enableImportButton();
        } else {
            this.disableImportButton();
        }
    }

    private disableImportButton() {
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.title = i18n.t("locale_file_imports.format_hint");
    }

    private enableImportButton() {
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.title = "";
    }

    private refreshFormOptions(format: string) {
        const providesPreview = this.formats[format]["provides_preview"];

        if (providesPreview) {
            this.submitButtonTarget.classList.add("hidden");
            this.submitToPreviewButtonTarget.classList.remove("hidden");

            this.localeSelectionTarget.classList.add("hidden");
            this.hintForNoneLocalesTarget.classList.add("hidden");
            this.hintForPreviewTarget.classList.remove("hidden");
            return;
        } else {
            this.submitButtonTarget.classList.remove("hidden");
            this.submitToPreviewButtonTarget.classList.add("hidden");
        }

        const includesLocaleInformation = this.formats[format]["includes_locale_information"];
        const allowLocaleSelection = this.formats[format]["allow_locale_selection"];

        if (allowLocaleSelection && includesLocaleInformation) {
            this.detectLocaleTarget.classList.remove("hidden");
            this.localeSelectionTarget.classList.remove("hidden");
            this.hintForNoneLocalesTarget.classList.add("hidden");
            this.hintForPreviewTarget.classList.add("hidden");
        } else {
            this.detectLocaleTarget.classList.add("hidden");
            if (!allowLocaleSelection && includesLocaleInformation) {
                this.localeSelectionTarget.classList.add("hidden");
                this.hintForNoneLocalesTarget.classList.remove("hidden");
                this.hintForPreviewTarget.classList.add("hidden");
            } else {
                this.localeSelectionTarget.classList.remove("hidden");
                this.hintForNoneLocalesTarget.classList.add("hidden");
                this.hintForPreviewTarget.classList.add("hidden");
            }
        }
    }
}
