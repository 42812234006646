import Sentry from "@utils/sentry";
import DataUtils from '@utils/data_utils';
import DomUtils from '@utils/dom_utils';
import { Controller } from 'stimulus';
import { i18n } from '../../i18n/config';

interface TranslationKey {
    id: string;
    name: string;
}

export default class extends Controller {
    static targets = [
        "searchKeysContainer",
        "searchKeysList",
        "searchKeyTemplate",
        "searchKey",
        "scrollLoadingMessage",
        "emptySearchMessage",
        "searchInput",
        "addSelectionButton",
        "searchKeyCheckbox",
        "selectAllCheckbox",
        "selectAllKeysContainer",
        "keyCount",
        "loadingKeysMessage",
        "warningToast",
    ];

    private readonly MAX_PER_PAGE = 100;

    private searchKeysContainerTarget: HTMLDivElement;
    private searchKeysListTarget: HTMLDivElement;
    private searchKeyTemplateTarget: HTMLTemplateElement;
    private searchKeyTargets: HTMLElement[];
    private searchKeyCheckboxTargets: HTMLInputElement[];
    private scrollLoadingMessageTarget: HTMLDivElement;
    private searchInputTarget: HTMLInputElement;
    private emptySearchMessageTarget: HTMLDivElement;
    private addSelectionButtonTarget: HTMLButtonElement;
    private selectAllCheckboxTarget: HTMLInputElement;
    private selectAllKeysContainerTarget: HTMLDivElement;
    private keyCountTarget: HTMLDivElement;
    private loadingKeysMessageTarget: HTMLDivElement;
    private warningToastTarget: HTMLTableSectionElement;

    private searchPage = 0;
    private lastPageReached = false;
    private selectedKeyCodes = new Set<string>();
    private totalCount: number;

    connect() {
        const selectedCodes: string[] = JSON.parse(this.data.get("selectedKeyCodes"));
        selectedCodes.forEach(code => this.selectedKeyCodes.add(code));
        this.searchKeys();
    }

    scroll() {
        const reachedBottom = this.searchKeysContainerTarget.scrollHeight - this.searchKeysContainerTarget.scrollTop === this.searchKeysContainerTarget.clientHeight;
        if (!this.lastPageReached && reachedBottom) {
            DomUtils.showElement(this.scrollLoadingMessageTarget);
            this.searchKeysContainerTarget.scrollTop = this.searchKeysContainerTarget.scrollHeight;
            this.searchPage++;
            this.searchKeys(true);
        } else {
            DomUtils.hideElement(this.scrollLoadingMessageTarget);
        }
    }

    addKey(event: Event) {
        event.preventDefault();

        if (this.selectedKeyCodes.size >= this.maxResults) {
            this.warningToastTarget.classList.remove("hidden");
            return;
        }
        const keyElement = (event.currentTarget as HTMLElement).closest('[data-target="jobs--key-search.searchKey"]');
        this.selectKey(keyElement as HTMLElement);
    }

    async addKeySelection(event: Event) {
        event.preventDefault();

        if (this.isLocked()) {
            return;
        }

        this.searchKeyCheckboxTargets.filter(checkbox => checkbox.checked).forEach(searchKeyCheckbox => {
            const keyElement = searchKeyCheckbox.closest('[data-target="jobs--key-search.searchKey"]') as HTMLElement;
            this.selectKey(keyElement);
        });

        if (this.selectAllCheckboxTarget.checked && this.searchKeyCheckboxTargets.length >= this.MAX_PER_PAGE) {
            this.addSelectionButtonTarget.disabled = true;
            await this.selectAllKeys();
        }

        const hasSelected = this.searchKeyCheckboxTargets.some(checkbox => checkbox.checked);
        this.addSelectionButtonTarget.disabled = !hasSelected;
    }

    search(event: KeyboardEvent) {
        if (event.key !== "Enter") {
            return;
        }

        this.searchPage = 0;
        this.lastPageReached = false;
        this.searchKeys();
    }

    toggleKey(event: Event) {
        const target = event.currentTarget as HTMLInputElement;
        if (this.isCurrentSelectionReachingLimit()) {
            this.preventSelectionOnLimitReach(target);
            return;
        }
        this.setKeyCheckboxSelection(target, target.checked);

        this.selectAllCheckboxTarget.checked = false;
        this.addSelectionButtonTarget.disabled = !this.searchKeyCheckboxTargets.some(checkbox => checkbox.checked);
    }

    toggleAllKeys(event: Event) {
        const target = event.currentTarget as HTMLInputElement;
        if (this.selectedKeyCodes.size >= this.maxResults) {
            this.preventSelectionOnLimitReach(target);
            return;
        }
        const checked = target.checked;
        this.searchKeyCheckboxTargets.forEach(searchKeyCheckbox => {
            this.setKeyCheckboxSelection(searchKeyCheckbox, checked);
        });

        this.addSelectionButtonTarget.disabled = !checked;
    }

    enableKey(event: CustomEvent) {
        const key: TranslationKey = event.detail;
        this.selectedKeyCodes.delete(key.id);

        this.searchKeyTargets.forEach(elem => {
            if (elem.getAttribute("data-jobs--key-search-code") === key.id) {
                this.updateSearchResult(elem, false);
            }
        });
    }

    private selectKey(elem: HTMLElement) {
        const keyName = elem.getAttribute("data-jobs--key-search-name");
        const keyCode = elem.getAttribute("data-jobs--key-search-code");
        this.updateSearchResult(elem, true);

        const key: TranslationKey = {
            name: keyName,
            id: keyCode,
        };
        this.addSelectedKey(key);
    }

    private addSelectedKey(key: TranslationKey) {
        if (!this.selectedKeyCodes.has(key.id)) {
            const event = new CustomEvent("jobs--add-key", { detail: key });
            this.element.dispatchEvent(event);
            this.selectedKeyCodes.add(key.id);
        }
    }

    private isLocked(): boolean {
        return (this.data.get("job-locked") === "true");
    }

    private isCurrentSelectionReachingLimit(): boolean {
        return this.selectedKeyCodes.size + this.searchKeyCheckboxTargets.filter(checkbox => checkbox.checked).length > this.maxResults;
    }

    private setKeyCheckboxSelection(target: HTMLInputElement, checked: boolean) {
        if (target.disabled) {
            return;
        }

        target.checked = checked;
        const parent = target.closest(".key-selection__key-container") as HTMLElement;
        if (checked) {
            parent.classList.remove("key-selection__key-container--unchecked");
            parent.classList.add("key-selection__key-container--checked");
        } else {
            parent.classList.add("key-selection__key-container--unchecked");
            parent.classList.remove("key-selection__key-container--checked");
        }
    }

    private searchKeys(append = false) {
        if (!append) {
            this.searchKeyTargets.forEach(key => key.remove());
            DomUtils.showElement(this.loadingKeysMessageTarget);
        }

        this.fetchKeys(this.searchPage)
            .then(resp => {
                this.totalCount = JSON.parse(resp.headers.get("Pagination"))["total_count"];
                if (this.totalCount > this.maxResults) {
                    this.keyCountTarget.textContent = i18n.t("jobs.select_first_max_keys", {
                        max: this.maxResults,
                    });
                } else {
                    this.keyCountTarget.textContent = i18n.t("jobs.select_all", {
                        key_count: this.totalCount,
                    });
                }

                return resp.json();
            })
            .then((keys: TranslationKey[]) => {
                if (keys.length < this.MAX_PER_PAGE) {
                    this.lastPageReached = true;
                }
                this.addSelectionButtonTarget.disabled = true;
                this.selectAllCheckboxTarget.checked = false;
                this.populateSearchResults(keys);
            })
            .catch(err => {
                if (err.errors) {
                    Sentry.notify(err.errors);
                } else {
                    Sentry.notify(err);
                }
            })
            .finally(() => {
                DomUtils.hideElement(this.loadingKeysMessageTarget);
            });
    }

    private async selectAllKeys() {
        this.selectAllCheckboxTarget.checked = false;
        let page = 0;

        document.getElementById('job-submit-btn').setAttribute('disabled', '');
        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const response = await this.fetchKeys(page);
                const keys = await response.json();
                keys.forEach((key: TranslationKey) => this.addSelectedKey(key));
                if (keys.length < this.MAX_PER_PAGE || this.selectedKeyCodes.size >= this.maxResults) {
                    break;
                }
                page++;
            } catch (err) {
                Sentry.notify(err);
                break;
            }
        }

        document.getElementById('job-submit-btn').removeAttribute('disabled');
    }

    private async fetchKeys(page: number): Promise<any> {
        const url = new URL(this.searchUrl);
        url.searchParams.append("page", page.toString());
        url.searchParams.append("per_page", this.MAX_PER_PAGE.toString());
        let urlStr = url.toString();

        if (this.query) urlStr = urlStr + `&q=${encodeURIComponent(this.query)}`;

        return DataUtils.request(
            urlStr,
            void 0,
            {} as any,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "fetch" }
            });
    }

    private populateSearchResults(keys: TranslationKey[]) {
        DomUtils.hideElement(this.scrollLoadingMessageTarget);
        keys.forEach(this.addSearchResult.bind(this));
        if (this.searchKeyTargets.length == 0) {
            DomUtils.showElement(this.emptySearchMessageTarget);
            DomUtils.hideElement(this.selectAllKeysContainerTarget);
            this.selectAllCheckboxTarget.checked = false;
        } else {
            DomUtils.hideElement(this.emptySearchMessageTarget);
            DomUtils.showElement(this.selectAllKeysContainerTarget);
        }
    }

    private addSearchResult(key: TranslationKey) {
        const keyElement = (this.searchKeyTemplateTarget.cloneNode(true) as HTMLTemplateElement).content.firstChild as HTMLElement;
        keyElement.setAttribute("data-jobs--key-search-name", key.name);
        keyElement.setAttribute("data-jobs--key-search-code", key.id);
        keyElement.querySelector("span").textContent = key.name;
        keyElement.querySelector("[data-controller=tooltip]").setAttribute('title', key.name);
        if (this.selectedKeyCodes.has(key.id)) {
            this.updateSearchResult(keyElement, true);
        }

        this.searchKeysListTarget.appendChild(keyElement);
    }

    private updateSearchResult(keyElement: HTMLElement, disable: boolean) {
        (keyElement.querySelector('[data-action="click->jobs--key-search#addKey"') as HTMLButtonElement).disabled = disable;
        const checkbox = keyElement.querySelector("input") as HTMLInputElement;
        checkbox.disabled = disable;
        checkbox.checked = false;

        keyElement.classList.add("key-selection__key-container--unchecked");
        keyElement.classList.remove("key-selection__key-container--checked");
    }

    private preventSelectionOnLimitReach(target: HTMLInputElement) {
        target.checked = false;
        this.warningToastTarget.classList.remove("hidden");
    }

    private get query(): string {
        const searchQuery = this.searchInputTarget.value.trim();
        return this.replaceAll(searchQuery, "tag:", "tags:");
    }

    replaceAll(string: string, search: string, replace: string) {
        return string.split(search).join(replace);
    }

    private get searchUrl(): string {
        return this.data.get('search-url');
    }

    private get maxResults(): number {
        return parseInt(this.data.get('max-results'));
    }
}
